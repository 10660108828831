import React from 'react'
import portfolioBanner from '../../assets/images/portfolioBanner.png'
import Button from '../../components/button/Button'
import ScrollAnimation from 'react-animate-on-scroll'
import ChooseUS from '../indexPage/chooseUs'

const dataArr = [
  {
    subtitle: "Expertise in Modern Technologies:",
    title: "We harness the latest technologies to create scalable and impactful digital solutions.",
  },
  {
    subtitle: "Customized Strategies:",
    title: "Tailored approaches designed to meet specific business needs, ensuring optimal outcomes.",
  },
  {
    subtitle: "Proven Track Record:",
    title: "With a history of successful projects, we are trusted by businesses across various industries.",
  },
]
const PortfolioBanner = () => {
  return (
    <div className='py100'>
      <div className="container">
        <div className='flex justify-between max-[991px]:flex-wrap max-[991px]:justify-center max-[991px]:gap-6 items-center'>
        <div className='xl:w-[50%] w-full animate__animated animate__fadeInUp'>
          <h1 className='fs45 text-[#1D2C38] font-bold'>Experience Our  <span className='text-[#04B0B0]'> Expertise </span></h1>
          <p className='fs24 text-[#302F2F] font-regular leading-relaxed my-10 max-[1680px]:my-6 max-[1370px]:my-4 max-[991px]:my-3'>At Pixel Pace Technologies, we go beyond traditional software development outsourcing; we create long-term partnerships to help businesses grow and thrive in the digital landscape. Based in Australia and New Zealand, we specialize in website design, digital marketing, UI/UX design, eCommerce solutions, and mobile app development to deliver exceptional, results-driven digital experiences.</p>
          <p className='fs24 text-[#302F2F] font-regular leading-relaxed my-10 max-[1680px]:my-6 max-[1370px]:my-4 max-[991px]:my-3'>Our portfolio highlights the successful projects we have delivered across different industries, demonstrating our commitment to innovation, quality, and client satisfaction. From custom website development to SEO-optimized digital marketing campaigns, our work is tailored to enhance brand presence, customer engagement, and online visibility.</p>
          {/* <Button btnTxt='Learn More About Us' className='max-w-[330px] w-full h-[85px] bg-[#04B0B0] rounded-[10px] fs22 font-semibold text-white max-[1570px]:max-w-[250px] max-[1570px]:h-[70px] max-[1370px]:max-w-[205px] max-[1370px]:h-[55px] max-[575px]:h-[45px] max-[575px]:max-w-[185px]' /> */}
        </div>
        <div className='xl:w-[45%] w-full animate__animated animate__fadeInUp'>
          <img src={portfolioBanner} alt="" />
        </div>
        </div>
<ChooseUS/>
      </div>
    </div>
  )
}

export default PortfolioBanner
