import React, { useEffect } from 'react';
import Modal from 'react-modal';
import CrossIcon from '../../assets/images/CrossIcon';
import confetti from 'canvas-confetti';
import videoBanner from '../../assets/media/video/addsVideos.mp4'
import {
     useLocation
  } from 'react-router-dom';
import './modal.scss'
const RefreshModal = ({ isOpen, onRequestClose, setIsOpen }) => {

    const location = useLocation();
    useEffect(() => {
      if (location.pathname === '/') {
        setIsOpen(true);
      }
    }, [location.pathname]);

    // Function to run the confetti effect
    const runConfetti = () => {
        setTimeout(function() {
            confetti({
                particleCount: 150,
                spread: 100,
                origin: { y: 0.3 }
            });
        }, 1000);
        
    };

    useEffect(() => {
        let confettiInterval;

        if (isOpen) {
            runConfetti(); // Run once immediately
            // Set up an interval for continuous effect
            confettiInterval = setInterval(runConfetti, 1500); // Adjust time as needed
        }

        // Clean up interval when modal closes
        return () => clearInterval(confettiInterval);
    }, [isOpen]);



    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Web Development Modal"
            className="custom-modal"
            overlayClassName="custom-overlay"
        >
            <div className="flex justify-between items-center xxl:mb-4 mb-2">
                <h2 className='xxl:text-2xl font-bold text-black text-lg'>Discount Offer</h2>
                <div onClick={onRequestClose}>
                    <CrossIcon className='xxl:w-[20px] xxl:h-[20px] w-[16px] h-[16px] cursor-pointer' iconClr='#000' />
                </div>
            </div>

            <div>
                
                <div className="modalVideo">
                <video muted loop autoPlay playsInline >
                    <source src={videoBanner} type="video/mp4" />
                </video>
                </div>
                <div className='flex gap-[10px] flex-col items-center bg-white p-3'>
                    <p className='text-[14px] font-semibold max-[1680px]:text-[12px] max-[1370px]:text-[11px]'>
                    Dive into the Easter spirit with our special promotion and take your social media marketing to the next level. Contact us now to explore how we can fulfill your unique digital promotion needs.
                    </p>
                    <a href="https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today" target='_blank' rel="noreferrer" className='bg-black w-fit p-3 text-white text-[12px] opacity-100 rounded-md flex justify-center items-center transition-all duration-300 hover:bg-transparent border-[1px] border-transparent hover:text-black font-semibold hover:border-black whitespace-nowrap'>Book a Dicovery Call</a>
                </div>

            </div>

        </Modal>
    );
}

export default RefreshModal;
