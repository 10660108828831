import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDesignBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle: "Creative & Custom Designs:",
    title: "Our web designs are tailored to reflect your brand identity, ensuring a unique and impactful online presence.",
  },
  {
    subtitle: "User Experience Focused:",
    title: "We build intuitive and responsive designs that enhance engagement and encourage user interaction.",
  },
  {
    subtitle: "SEO & Performance-Optimized:",
    title: "Every design is structured for higher search engine rankings, helping you attract and retain customers.",
  },
  {
    subtitle: "Conversion-Driven Approach:",
    title: "Our strategic web designs aim to boost leads, sales, and business growth.",
  },
]
const WebDesignSer = () => {
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className='container'>
        <BrowserTabTitle tabTitle={'Professional Web Design in Australia & NZ | Pixel Pace Technologies'} metaDescription={`Pixel Pace offers expert web design services in Australia & NZ. Get a visually stunning, user-friendly, and SEO-optimized website for your business growth.`} />
        <ServicePage title="Pixel Pace Technologies: Exceptional Web Design for Australia, NZ & Beyond" subTitle='Creating Visually Stunning & High-Performing Websites' detail="At Pixel Pace Technologies, we specialize in modern, engaging, and SEO-friendly web design that not only captivates visitors but also drives conversions. Our expert team ensures that your website stands out in the competitive digital landscape, offering a seamless user experience that aligns with your business goals in Australia, New Zealand, and globally." />

        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for Web Design?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default WebDesignSer
