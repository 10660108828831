import React from 'react'
import {
    Route,
    Routes
} from 'react-router-dom';
import IndexPage from '../pages/indexPage/IndexPage';
import PortfolioPage from '../pages/portfolioPage/PortfolioPage';
import WebDevSer from '../pages/servicePage/WebDevSer';
import WebDesignSer from '../pages/servicePage/WebDesignSer';
import Marketing from '../pages/servicePage/Marketing';
import UxUiDes from '../pages/servicePage/UxUiDes';
import Wordpress from '../pages/servicePage/Wordpress';
import Shopify from '../pages/servicePage/Shopify';
import PricingPage from '../pages/pricingPage/PricingPage';
import ContactUsPage from '../pages/contactUS/ContactUsPage';
import AboutUS from '../pages/indexPage/aboutUs/AboutUS';
import MobApp from '../pages/servicePage/MobApp';
import Thankfulness from '../pages/contactUS/getInTouch/Thankfulness';
import BlogPage from '../pages/blogs/BlogPage';
import BlogDetail from '../pages/blogs/BlogDetail';


const AppRouter = () => {

    return (
        <Routes>
            <Route path='/' index element={<IndexPage />} />
            <Route path="/Portfolio" element={<PortfolioPage />} />
            <Route path="/web-development" element={<WebDevSer />} />
            <Route path="/web-design" element={<WebDesignSer />} />
            <Route path="/digital-marketing" element={<Marketing />} />
            <Route path="/ux-ui-design" element={<UxUiDes />} />
            <Route path="/wordpress" element={<Wordpress />} />
            <Route path="/shopify" element={<Shopify />} />
            <Route path="/mob-app" element={<MobApp />} />
            <Route path="/Pricing" element={<PricingPage />} />
            <Route path="/get-in-touch" element={<ContactUsPage />} />
            <Route path="/AboutUS" element={<AboutUS />} />
            <Route path="/thankfull-message" element={<Thankfulness />} />
            <Route exact path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogDetail />} /> {/* Dynamic Route for Blog Details */}
            {/* Add more routes here as needed */}
        </Routes>
    )
}

export default AppRouter
