import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDevBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
    {
      subtitle: "Custom Mobile App Development:",
      title: "Bespoke mobile applications tailored to your business needs for a unique and engaging user experience.",
    },
    {
      subtitle: "Mobile App Optimization:",
      title: "Improve app performance with UI/UX enhancements, speed optimization, and cross-device compatibility.",
    },
    {
      subtitle: "Integration Services:",
      title: "Seamlessly connect your app with essential third-party services and APIs for smooth functionality.",
    },
    {
      subtitle: "Continuous Support and Maintenance:",
      title: "Keep your app up-to-date, secure, and running smoothly while you focus on business growth.",
    },
  ]


const MobApp = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
      });
      const serviceBgStyle = {
        '--bg-image': `url(${bgImg})`,
      };
  return (
    <section className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'User-Friendly & Scalable Mobile Apps | Pixel Pace Technologies'} metaDescription={`Build powerful mobile apps with Pixel Pace’s development services in Australia & NZ. We design user-friendly, high-performance apps for business success.`} />
        <ServicePage title="Mobile App Development by Pixel Pace Technologies in Australia & NZ" subTitle='Transform Your Business with Custom Mobile Apps' detail="At Pixel Pace Technologies, we specialize in crafting high-performance mobile applications tailored to businesses in Australia and New Zealand. Whether you need a new mobile app or want to enhance an existing one, our expert team ensures a seamless user experience that drives customer engagement and business growth." />
        <div className='pt100' ref={ref}>
          <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace Technologies for Mobile App Development?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default MobApp
