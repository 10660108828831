import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/marketBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle: "SEO & Content Marketing:",
    title: "Improve rankings, attract quality traffic, and convert visitors into customers with expertly crafted SEO strategies.",
  },
  {
    subtitle: "Social Media Marketing & Management:",
    title: "Engage your audience on Facebook, Instagram, LinkedIn, and more, with creative content and targeted campaigns.",
  },
  {
    subtitle: "Pay-Per-Click (PPC) Advertising:",
    title: "Maximize ROI with Google Ads, Facebook Ads, and other paid campaigns optimized for conversions.",
  },
  {
    subtitle: "Email Marketing & Automation:",
    title: "Nurture leads with personalized email sequences that drive engagement and customer retention.",
  },
  {
    subtitle: "Strategic Growth Planning:",
    title: "Our team analyzes trends and audience behavior to craft winning marketing strategies customized to your business.",
  },
]
const Marketing = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Expert Digital Marketing Services in Australia & NZ | Pixel Pace Tech'} metaDescription={`Boost your brand with Pixel Pace’s digital marketing services in Australia & NZ. Get SEO, PPC, and social media strategies that drive traffic & conversions.`} />
        <ServicePage title="Digital Marketing Services in Australia & NZ" subTitle='Boost Your Brand with Data-Driven Marketing Strategies' detail="At Pixel Pace Technologies, we help businesses in Australia, New Zealand, and beyond thrive in the digital world with strategic, results-driven marketing solutions. Whether you aim to increase brand awareness, drive engagement, or boost conversions, our tailored strategies ensure your success." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>What We Offer:</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Marketing
