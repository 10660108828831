import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDevBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';
const dataArr = [
  {
    subtitle:"Bespoke Web Solutions:",
    title:"Our websites are designed to meet your unique business needs while ensuring optimal performance.",
  },
  {
    subtitle:"User-Friendly & Engaging Designs:",
    title:"We prioritize intuitive navigation, mobile responsiveness, and seamless user experiences.",
  },
  {
    subtitle:"SEO & Performance-Optimized:",
    title:"Every website we build is structured to rank higher on search engines, ensuring maximum visibility.",
  },
  {
    subtitle:"Results-Driven Approach:",
    title:"We focus on conversions, engagement, and lead generation to help businesses scale.",
  },
]
const WebDevSer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});

  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container h-full flex flex-col">
        <BrowserTabTitle tabTitle={'Professional Web Development Services in Australia & NZ | Pixel Pace'} metaDescription={`Get a high-performance, SEO-optimized website with Pixel Pace Technologies. We build user-friendly websites Bespoke for business growth in Australia & NZ.`} />
        <ServicePage title="Web Development Services at Pixel Pace Technologies in Australia & NZ" subTitle='Bringing Your Vision to Life with Powerful Digital Solutions' detail="At Pixel Pace Technologies, we specialize in crafting high-performance websites that blend innovation, functionality, and aesthetics. Whether you're looking to establish a new digital presence or enhance your existing one, our expert team develops responsive, SEO-friendly, and user-centric websites designed for business success in Australia, New Zealand, and beyond." />
        <div className='pt100' ref={ref}>
            <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for Web Development?</h3>
          {
            dataArr.map((data,index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
              <p className='fs22 leading-normal'>
                <b className='mr-1'>
                  {data.subtitle} 
                </b>
                {data.title}
              </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default WebDevSer
