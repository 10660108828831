import React from 'react'
import PortfolioBanner from './PortfolioBanner'
import BookAppoinment from '../indexPage/BookAppoinment'
import PortfolioWrap from '../indexPage/portfolioWrap/PortfolioWrap'
// import TrustedClient from '../indexPage/trustedClient/TrustedClient'
import Quote from '../indexPage/Quote'
// import Testimonial from '../indexPage/testimonial/Testimonial'
import ContactUs from '../contactUS/ContactUs'
import BrowserTabTitle from '../../components/browserTabTitle'

const PortfolioPage = () => {
    return (
        <>
          <BrowserTabTitle tabTitle={'Web Design & Digital Marketing Portfolio by Pixel Pace in Australia & NZ'} metaDescription={`Check out Pixel Pace’s latest projects in web design, UI/UX, and digital marketing for businesses in Australia & NZ. We build high-performance digital solutions.`} />
          <PortfolioBanner />
          <BookAppoinment/>
          <PortfolioWrap/>
          {/* <TrustedClient/> */}
          <Quote/>
          {/* <Testimonial/> */}
          <ContactUs/>
        </>
    )
}

export default PortfolioPage
