import React, { useRef } from 'react'
import aboutImg from '../../../assets/images/technology_img_right.png'
import Statistics from '../Statistics'
import ScrollAnimation from 'react-animate-on-scroll';

const AboutUS = () => {

    return (
        <section className='pt100' id='aboutUsSec'>
            <div className="container flex md:justify-between items-center md:flex-nowrap lg:gap-0 md:gap-x-6 flex-wrap gap-4 justify-center">
                <div className={`md:w-[45%] w-full`}>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <span className="text-[#04B0B0] fs20 block font-semibold">About Us</span>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <h2 className="fs40 text-[#1D2C38] font-extrabold mt-5 max-[1680px]:mt-4 max-[1370px]:mt-3 max-[575px]:mt-2">Your Digital Partner for Web Design & Marketing Success</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <p className="text-[#454545] fs18 font-normal min-[1370px]:my-7 my-4 max-[575px]:my-2 leading-normal">
                            Pixel Pace Technologies is a leading website design & digital marketing agency serving Australia & New Zealand. We specialize in SEO-friendly web design, branding, eCommerce solutions, and digital marketing strategies tailored to help businesses grow online.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <ul className='flex flex-col gap-2 list-disc ps-5'>
                            <li>
                                <h6 className="text-black fs20 font-bold">
                                    7+ Years of experience
                                </h6>
                            </li>
                            <li>
                                <h6 className="text-black fs20 font-bold">
                                Industries served (eCommerce, startups, agencies, SMEs, etc.)
                                </h6>
                            </li>
                            <li>
                                <h6 className="text-black fs20 font-bold">
                                Customer-centric approach & innovative solutions
                                </h6>
                            </li>
                        </ul>

                    </ScrollAnimation>
                    {/* <Button btnTxt="Learn More About Us" className="bg-[#04B0B0] h-[85px] fs22 max-w-[331px] w-full font-semibold mt-16 max-[1680px]:mt-7 text-white max-[1680px]:max-w-[250px] max-[1680px]:h-[75px] max-[1500px]:max-w-[215px] max-[1500px]:h-[65px] max-[1370px]:max-w-[190px] max-[1370px]:h-[55px]" /> */}
                </div>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className='md:w-[50%] w-full min-[1690px]:h-[590px] min-[1370px]:h-[520px] lg:h-[450px] h-[330px]'>
                    <img src={aboutImg} alt="Pixel Pace Technologies team working on website design and digital marketing projects." className='object-cover h-full' />
                </ScrollAnimation>
            </div>
            <Statistics />
        </section>
    )
}

export default AboutUS
