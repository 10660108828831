import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../components/button/Button'
import './index.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import { useInView } from 'react-intersection-observer';
const tabsData = [
    {
        id: "pills1",
        label: "Web Development",
        link: '/web-development',
        imageSrc: require("../../../assets/images/service1.png"),
        subHeading: "Web Development",
        heading: "Full-Stack Web Development Solutions",
        detail: "We build modern, fast, and secure websites tailored to your business goals. Whether you need a custom-built web app or a scalable business site, our developers turn ideas into functional platforms ready to grow with your business.",
        animate1: "fadeInUp",
        animate2: "fadeInUp",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom Web Features",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Performance Analytics",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Expert Guidance",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Secure Architecture",
            },
        ]
    },
    {
        id: "pills2",
        label: "Web Design",
        imageSrc: require("../../../assets/images/service2.png"),
        subHeading: "Web Design",
        link: '/web-design',
        heading: "Creative Web Design with User Focus",
        detail: "Our design team crafts clean, mobile-friendly websites that reflect your brand and engage your audience. We balance beautiful visuals with usability to ensure your visitors enjoy every click.",
        animate1: "",
        animate2: "",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Clean, Responsive Design",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Conversion-Focused Layouts",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easy-to-Edit Sections",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Engaging UX Elements",
            },
        ]
    },
    {
        id: "pills3",
        label: "Wordpress",
        imageSrc: require("../../../assets/images/service3.png"),
        subHeading: "WordPress",
        link: '/wordpress',
        heading: "Custom WordPress Websites",
        detail: "From setup to launch, we create flexible and secure WordPress websites that are easy to manage and optimized for SEO and performance. Perfect for startups, blogs, and service-based businesses.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "SEO-Ready Themes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Mobile-Friendly Layouts",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Admin-Friendly Dashboard",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Plugin Integration",

            },
        ]
    },
    {
        id: "pills4",
        label: "Shopify",
        link: '/shopify',
        imageSrc: require("../../../assets/images/service4.png"),
        subHeading: "Shopify",
        heading: "Shopify Store Design & Development",
        detail: "Launch or revamp your Shopify store with our expert design and development services. We create sleek, secure, and sales-driven eCommerce websites tailored to your products and audience.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Optimized Product Pages",


            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Fast & Secure Checkout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Mobile Commerce Ready",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "User-Friendly Admin Panel",

            },
        ]
    },
    {
        id: "pills5",
        label: "Marketing",
        link: '/digital-marketing',
        imageSrc: require("../../../assets/images/service5.png"),
        subHeading: "Digital Marketing",
        heading: "Complete Digital Marketing Services",
        detail: "We help you attract, convert, and retain customers through SEO, paid advertising, social media marketing, and content strategies. Let’s grow your online presence and bring more traffic to your business.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "SEO Strategies That Work",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Targeted Ad Campaigns",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Social Media Growth",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Content That Connects",

            },
        ]
    },
    {
        id: "pills6",
        label: "UI UX Design",
        link: '/ux-ui-design',
        imageSrc: require("../../../assets/images/service6.png"),
        subHeading: "UI/UX Design",
        heading: "Intuitive UI/UX Design for Seamless Experiences",
        detail: "We focus on creating user-friendly interfaces and smooth user journeys that align with your goals and keep your audience engaged across all devices.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "User-Centric Interfaces",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Modern Visual Aesthetics",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Frictionless Navigation",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Interactive Prototypes",

            },
        ]
    },
    {
        id: "pills7",
        label: "Mobile Development",
        link: '/mob-app',
        imageSrc: require("../../../assets/images/mob-app.jpg"),
        subHeading: "Mobile Development",
        heading: "Complete Mobile App Development",
        detail: "We help businesses build intuitive, high-performing mobile apps that connect with users on the go. Whether you're targeting Android, iOS, or both, our mobile development team delivers sleek, scalable, and secure apps designed for real-world impact. From idea to launch — we cover it all.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Cross-Platform Support",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "User-Focused Interfaces",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Seamless API Integration",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "App Store Deployment",

            },
        ]
    },
    {
        id: "pills8",
        label: "NFT Website",
        imageSrc: require("../../../assets/images/service7.png"),
        subHeading: "NFT Website",
        heading: "Next-Gen NFT Web Development",
        detail: "Step into the future of the web with secure, creative NFT platforms. We help artists and innovators showcase and sell digital assets with custom NFT website solutions.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Secure Smart Contract Integration",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Wallet-Ready Functionality",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Minting Made Simple",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom Marketplace Design",

            },
        ]
    },
];
const ServicesBox = () => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [activeTab, setActiveTab] = useState(tabsData[0].id);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    return (
        <section className='pb100 min-[1690px]:pt-[200px] min-[1370px]:pt-[165px] min-[1200px]:pt-[150px] md:pt-[130px] pt-[175px] bg-[#006363]'>
            <div className="container">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                    <span className='block text-center fs20 font-semibold text-white'>Services we provide</span>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                    <h2 className='fs40 text-center font-bold md:w-[70%] mx-auto text-white pt-1 mb-3 leading-tight'>End-to-End <span className='text-[#0FE3E3]'>Web Design</span> & <span className='text-[#0FE3E3]'>Digital Marketing</span> Services to Grow Your Brand</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                    <p className='fs16 text-white xl:w-[80%] w-full mx-auto text-center pb80 leading-normal'>At Pixel Pace Technologies, we help businesses across Australia and New Zealand thrive online. From custom web design to digital marketing, SEO, and branding — our expert team delivers smart, scalable solutions that drive real growth.</p>
                </ScrollAnimation>
                <div>
                    <div className="flex lg:justify-between justify-center lg:flex-nowrap flex-wrap min-[1680px]:mb-16 mb-8 lg:gap-0 gap-y-2 gap-x-4">
                        {tabsData.map((tab) => (
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} key={tab.id}>
                                <button
                                    className={`text-white pb-7 fs24 font-normal max-[1680px]:pb-3 max-[1370px]:pb-1 ${activeTab === tab.id ? "border-b-2 border-white font-semibold opacity-100" : "opacity-80"}`}
                                    id={`${tab.id}Tab`}
                                    onClick={() => handleTabClick(tab.id)}
                                    type="button"
                                    role="tab"
                                    aria-controls={tab.id}
                                    aria-selected={activeTab === tab.id}
                                >
                                    {tab.label}
                                </button>
                            </ScrollAnimation>
                        ))}
                    </div>
                    <div className='min-[1680px]:mb-16 mb-6'>
                        {tabsData.map((tab) => (
                            <div
                                className={`servicesWrap flex justify-between items-center md:flex-row flex-col md:gap-0 gap-6 tabPane transition-opacity duration-300 ${activeTab === tab.id ? "opacity-100" : "opacity-0 hidden"
                                    }`}
                                id={tab.id}
                                role="tabpanel"
                                aria-labelledby={`${tab.id}Tab`}
                                tabIndex="0"
                                key={tab.id}
                            >
                                <div ref={ref} className={`serviceBox min-[1690px]:h-[585px] min-[1370px]:h-[400px] md:h-[360px] h-[300px] ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                                    <img className='w-full h-full min-[1690px]:object-fill object-contain object-center' src={tab.imageSrc} alt={`${tab.label} Image`} />
                                </div>
                                <div ref={ref} className={`serviceBox min-[1690px]:pr-12 pr-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                                    <h6 className=' fs20 font-semibold text-white'>{tab.subHeading}</h6>
                                    <h3 className=' fs40 font-bold leading-normal text-white mt-2 mb-5 max-[1500px]:leading-tight max-[1500px]:mb-4 max-[1370px]:mb-2 max-[1370px]:mt-1'>{tab.heading}</h3>
                                    <p className=' fs18 leading-normal text-white'>{tab.detail}</p>
                                    <div className='grid lg:grid-cols-2 gap-y-3 min-[1690px]:mt-9 mt-6 max-[1370px]:mt-4'>
                                        {
                                            tab.serviceLiArr?.map((serviceLiData, index) => (
                                                <div className=' flex gap-[10px] items-center xl:first-line:max-w-[250px] w-full max-[1370px]:gap-[7px]' key={index}>
                                                    <div className='w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#04B0B0] max-[1370px]:w-[25px] max-[1370px]:h-[25px]'>
                                                        <img src={serviceLiData.img} className='max-[1370px]:w-[12px]' alt="" />
                                                    </div>

                                                    <p className='text-white fs18'>{serviceLiData.liTitle}</p>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                        <Button shadowClr='rgba(0, 0, 0, 0.5)' btnLink={tabsData.find((tab) => tab.id === activeTab)?.link} btnTxt="Read more" className="readMore bg-white fs18 text-[#006363] w-fit px-8 py-5 mx-auto font-medium max-[1500px]:px-6 max-[1500px]:py-4 max-[1370px]:px-4 max-[1370px]:py-3 max-[575px]:px-4 before:bg-black hover:text-white" />
                    </ScrollAnimation>

                </div>
            </div>
        </section>
    );
};

export default ServicesBox;
