import React, { useState, useEffect } from 'react';
import './index.css';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const moveCursor = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
      setIsVisible(true);
    };

    window.addEventListener('mousemove', moveCursor);
    window.addEventListener('mouseout', () => setIsVisible(false));

    return () => {
      window.removeEventListener('mousemove', moveCursor);
      window.removeEventListener('mouseout', () => setIsVisible(false));
    };
  }, []);

  return (
    <div
      className={`customCursor fixed top-0 left-0 pointer-events-none z-50 ${isVisible ? 'block' : 'hidden'}`}
      style={{
        transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
        transition: 'transform 0.1s linear'  // Smooth transition with a duration of 100 milliseconds
      }}
    >
      <div className="w-2 h-2 bg-[#04B0B0] rounded-full">
        {/* <span className='bg-[#04B0B0] rounded-full w-full h-full'></span> */}
      </div>
    </div>
  );
};

export default CustomCursor;
