import React, { useState } from 'react'
import './Button.scss'

const Button = ({ btnIcon, btnTxt, className, btnLink, shadowClr, target, rel }) => {
  const [isHovered, setIsHovered] = useState(false);
  // Combine the passed className prop with the default classes
  const buttonClasses = `customBtn flex justify-center items-center gap-2 rounded-lg cursor-pointer ${className}`;

  return (
    <div >
      <a href={btnLink} className={buttonClasses} target={target} rel={rel} onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {btnIcon && <span>{btnIcon}</span>}
        {btnTxt}
        </a>
    </div>
  )
}

export default Button
