import React, { useEffect } from 'react'
import './workProcess.scss'
import Button from '../../../components/button/Button'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
const processArr = [
    {
        imgSrc: require('../../../assets/images/procee1.png'),
        alt: "Discovery & Planning at Pixel Pace Technologies",
        subTitle: "Phase 1",
        title: "Discovery & Planning",
        detail: <>
          We begin by understanding your business goals, audience, and challenges. Our experts conduct market research, competitor analysis, and strategic planning to ensure a results-driven approach tailored for businesses in Australia, New Zealand, and beyond.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee2.png'),
        alt: "Design & Development at Pixel Pace Technologies",
        subTitle: "Phase 2",
        title: "Design & Development",
        detail: <>
            Our creative designers and expert developers craft visually stunning, high-performance websites. We focus on mobile responsiveness, fast loading speeds, and user-friendly navigation, ensuring your website stands out globally while excelling in Australian & NZ markets.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee3.png'),
        alt: "Iterate & Test",
        subTitle: "Phase 3",
        title: "Iterate & Test",
        detail: <>
           Before launch, we conduct rigorous testing to refine designs, optimize speed, and enhance user experience. Our quality assurance process ensures your website meets the highest industry standards for performance and security.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee4.png'),
        alt: "Integrate & Support at Pixel Pace Technologies",
        subTitle: "Phase 4",
        title: "Integrate & Support",
        detail: <>
            We seamlessly integrate your website with essential platforms, including CRM systems, eCommerce solutions, and third-party tools. Our ongoing support and maintenance services ensure smooth functionality and scalability for businesses in Australia, New Zealand, and across the globe.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee5.png'),
        alt: "Digital Marketing at Pixel Pace Technologies",
        subTitle: "Phase 5",
        title: "Digital Marketing & SEO",
        detail: <>
            A great website needs visibility. We implement SEO strategies, paid advertising (PPC), social media marketing, and content marketing to help businesses rank higher on Google in Australia, New Zealand, and globally. Our focus is on driving organic traffic, generating leads, and increasing conversions.
        </>
    },
]
const WorkProcess = () => {
    // const { pathname } = useLocation();
    const backtoTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className='workProcess py100'>
            <div className="container">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <h2 className='fs45 text-white font-bold text-center'>Our Work Process</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <p className='text-center mt-4 fs18 leading-snug lg:w-[75%] mx-auto pb80'>
                At Pixel Pace Technologies, we follow a structured approach to website design, development, and digital marketing to deliver impactful solutions for businesses in Australia, New Zealand, and worldwide.
                </p>
                </ScrollAnimation>
                
                <div className='xl:p-0 px-2'>
                    {
                        processArr.map((processData, index) => (
                            <div key={index} className='workRow flex justify-between items-center lg:flex-row flex-col-reverse gap-x-6 lg:gap-y-0 gap-y-4'>
                                <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}  className='lg:w-1/2 min-[1690px]:h-[420px] min-[1370px]:h-[320px] h-[260px]'  >
                                    <img className='w-full h-full object-contain' src={processData.imgSrc} alt={processData.alt}  />
                                </ScrollAnimation>
                                <ScrollAnimation animateIn='fadeInRight' animateOnce={true}  className='lg:w-1/2'>
                                    <span   className='block text-[#0FE3E3] fs30 font-semibold'>{processData.subTitle}</span>
                                    <h3   className='text-white fs40 font-medium titleMain'>{processData.title}</h3>
                                    <p   className='fs20 font-medium'>{processData.detail}</p>
                                </ScrollAnimation>
                            </div>
                        ))
                    }
                </div>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <h3 className='text-white fs28 font-medium text-center'>Engage with Pixel Pace Today</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <p className='fs20 font-medium mt-2 mb-4 text-center lg:w-[75%] mx-auto'>
                    Ready to elevate your online presence? Click below to initiate your project with Pixel Pace Technologies and harness our expert services in Website Design and Digital Marketing to achieve unparalleled success.
                </p>
                </ScrollAnimation>
                
                <ScrollAnimation animateIn='fadeInDown' animateOnce={true} >
                <Link onClick={backtoTop} to='/'>
                    <Button btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Start a Project" className='w-fit bg-[#DFFFFF] fs20 text-[#003D3D] font-semibold mx-auto py-5 px-8 max-[1500px]:px-6 max-[1500px]:py-4 max-[1370px]:px-4 max-[1370px]:py-3 before:bg-black hover:text-white' shadowClr='rgba(0,0,0,0.5)' />
                </Link>
                </ScrollAnimation>
                
            </div>
        </div>
    )
}

export default WorkProcess
