import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersCog, faTasks, faPoll, faHeadset, faMicrochip, faDatabase, faDollarSign, faHandshake } from '@fortawesome/free-solid-svg-icons';
import './index.scss'
import ScrollAnimation from 'react-animate-on-scroll';

const dataArr = [
    {
        icon: faUsersCog,
        title: "Experienced Team",
        desc: "Skilled web designers, SEO experts, and digital marketers with a proven track record in Australia & NZ.",
    },
    {
        icon: faTasks,
        title: "Optimized Solutions",
        desc: "Custom web design, SEO, and digital marketing strategies that align with your business goals.",
    },
    {
        icon: faPoll,
        title: "Results-Driven SEO & PPC",
        desc: "Boost visibility with SEO, Google Ads, and social media marketing for measurable ROI.",
    },
    {
        icon: faHeadset,
        title: "24/7 Support",
        desc: "Reliable website maintenance, marketing assistance, and business growth support when you need it.",
    },
    {
        icon: faMicrochip,
        title: "Cutting-Edge Technology",
        desc: "We use the latest web development tools and digital marketing innovations to enhance your online presence.",
    },
    {
        icon: faDatabase,
        title: "Data-Driven Approach",
        desc: "Our strategies are powered by SEO analytics, keyword research, and conversion tracking for real growth.",
    },
    {
        icon: faDollarSign,
        title: "Affordable Pricing",
        desc: "Cost-effective website design, SEO, and digital marketing solutions for businesses of all sizes in Australia & NZ.",
    },
    {
        icon: faHandshake,
        title: "Long-Term Partnership",
        desc: "We focus on sustainable growth and long-term success through expert web and marketing strategies.",
    },
]

const ChooseUS = () => {
    return (
        <section className='py-[70px]'>
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2 className='text-center text-[#1D2C38] fs45 font-bold'>
                    Why Pixel Pace Technologies?
                </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p className='text-center mt-4 fs18 leading-snug lg:w-[75%] mx-auto'>
                    We are a leading digital agency in Australia & New Zealand, providing expert website design, SEO services, and digital marketing solutions to help businesses grow. Here’s why businesses trust us:
                </p>
                </ScrollAnimation>
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 min-[1500px]:gap-8 gap-6 xl:mt-12 mt-8">
                    {
                        dataArr.map((data, index) => (
                            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="min-[1500px]:px-6 px-4 min-[1500px]:py-8 py-6 rounded-lg text-center chooseBox" key={index}>
                                <div className="bg-[#CAFFFF] w-[70px] h-[70px] rounded-full mx-auto flex flex-col justify-center items-center iconBox">
                                    <FontAwesomeIcon icon={data.icon} className="text-[30px]" />
                                </div>
                                <h3 className='fs22 flex flex-col justify-center mb-3 mt-6'>{data.title}</h3>
                                <p className='fs18 leading-normal'>{data.desc}</p>
                            </ScrollAnimation>
                        ))
                    }

                </div>
            </div>

        </section>
    )
}

export default ChooseUS
