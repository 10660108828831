import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/WordpressBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';
const dataArr = [
  {
    subtitle: "Industry-Leading Expertise:",
    title: "With years of experience, we deliver WordPress websites that drive success across various industries.",
  },
  {
    subtitle: "Custom & Scalable Solutions:",
    title: "Our WordPress designs are built to meet your current needs and scale with your business growth.",
  },
  {
    subtitle: "Performance-Optimized & Secure:",
    title: "We ensure fast-loading, SEO-friendly, and fully secured WordPress websites.",
  },
  {
    subtitle: "Ongoing Support & Maintenance:",
    title: "From updates to security patches, we keep your website running smoothly 24/7.",
  },
  {
    subtitle: "eCommerce & WooCommerce Ready:",
    title: "We develop conversion-optimized online stores with secure payment gateways.",
  },
]
const Wordpress = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Professional WordPress Development in Australia & NZ | Pixel Pace'} metaDescription={`Get expert WordPress development in Australia & NZ with Pixel Pace. We build custom, SEO-optimized, and high-performance websites for business success.`} />
        <ServicePage title="WordPress Solutions by Pixel Pace Technologies in Australia & NZ" subTitle='SEO-Optimized Websites & Custom WordPress Development' detail="Pixel Pace Technologies specializes in SEO-friendly WordPress development tailored for businesses in Australia and New Zealand. Our expert team delivers high-performance, custom WordPress websites, secure eCommerce stores, and ongoing maintenance to keep your digital presence competitive. Whether you are a startup, blogger, or established business, we create responsive, fast-loading WordPress solutions that help you rank higher on Google and attract more customers." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace Technologies for WordPress Development?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Wordpress
