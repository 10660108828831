import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/UxUiDesBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle:"User Satisfaction First:",
    title:"We design with users in mind, ensuring easy navigation and engaging visuals for an effortless experience.",
  },
  {
    subtitle:"Personalized Design Solutions:",
    title:"Every project is customized to align with your brand identity and business goals.",
  },
  {
    subtitle:"Data-Driven Approach:",
    title:"We leverage user research & analytics to create designs that improve functionality and engagement.",
  },
  {
    subtitle:"Cross-Platform Consistency:",
    title:"Whether it's web, mobile, or software UI, we maintain a seamless design experience across all platforms.",
  },
]

const UxUiDes = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Expert UI/UX Design Services in Australia & NZ | Pixel Pace Tech'} metaDescription={`Boost user experience with Pixel Pace’s UI/UX design services in Australia & NZ. We create bespoke, and conversion-focused designs for web & mobile.`} />

        <ServicePage title="UI/UX Design Services in Australia & NZ" subTitle='Designing Seamless and Engaging Digital Experiences' detail="At Pixel Pace Technologies, we specialize in user-centric UI/UX design that ensures your website or application is visually captivating, intuitive, and optimized for engagement. Our designs focus on functionality, usability, and aesthetics, delivering an exceptional user experience for businesses in Australia, New Zealand, and beyond." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for UI/UX Design?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default UxUiDes
