import React, { useState } from 'react'
import './portfolio.scss'
import Button from '../../../components/button/Button'
import PortfolioArrow from '../../../assets/images/PortfolioArrow'
import PhoneIcon from '../../../assets/images/PhoneIcon'
import zoomIcon from '../../../assets/images/zoom_in.png'
import previewIcon from '../../../assets/images/arrow_insert.svg'
import { Link, useLocation } from 'react-router-dom'
import LightBox from './LightBox'
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoading from '../../../components/LazyLoading/LazyLoading'
const PortfolioWrap = () => {
    const location = useLocation();
    const isSpecificPage = location.pathname === '/Portfolio';

    let portfolioArr = [
        {
            imgSrc: require('../../../assets/images/portfolio20.png'),
            imgSrcShadow: require('../../../assets/images/portfolio20.png'),
            animate: 'fadeInLeft',
            smallImg: require('../../../assets/images/portfolio20_small.png'),
            link: 'https://hotelmoov.com/en/',
            isWhite: true,
        },
        {
            imgSrc: require('../../../assets/images/portfolio21.png'),
            imgSrcShadow: require('../../../assets/images/portfolio21.png'),
            animate: 'fadeInDown',
            link: 'https://www.solverde.pt/',
            smallImg: require('../../../assets/images/portfolio21_small.png'),
        },
        {
            imgSrc: require('../../../assets/images/portfolio22.png'),
            imgSrcShadow: require('../../../assets/images/portfolio22.png'),
            animate: 'fadeInRight',
            link: 'https://evanyrouse.com/en/',
            isWhite: true,
            smallImg: require('../../../assets/images/portfolio22_small.png'),
        },
        {
            imgSrc: require('../../../assets/images/portfolio23.png'),
            imgSrcShadow: require('../../../assets/images/portfolio23.png'),
            animate: 'fadeInleft',
            link: 'https://www.yesexpressservices.com/',
            isWhite: true,
            smallImg: require('../../../assets/images/portfolio23_small.png'),
        },
        {
            imgSrc: require('../../../assets/images/portfolio24.png'),
            imgSrcShadow: require('../../../assets/images/portfolio24.png'),
            animate: 'fadeInUp',
            link: 'https://www.epoca.pt/',
            smallImg: require('../../../assets/images/portfolio24_small.png'),
        },
        {
            imgSrc: require('../../../assets/images/portfolio19.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio19.jpg'),
            animate: 'fadeInRight',
            link: 'https://cyber-fighter.vercel.app/',
            smallImg: require('../../../assets/images/portfolio19_small.jpg'),
        },

    ];
    if (isSpecificPage) {
        portfolioArr = [
            ...portfolioArr,
            {
                imgSrc: require('../../../assets/images/portfolio11.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio11.jpg'),
                animate: 'fadeInLeft',
                link: 'https://happy-girls.vercel.app/',
                smallImg: require('../../../assets/images/portfolio11_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio12.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio12.jpg'),
                animate: 'fadeInDown',
                link: 'https://rubber-ducky-sepia.vercel.app/',
                smallImg: require('../../../assets/images/portfolio12_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio13.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio13.jpg'),
                animate: 'fadeInRight',
                link: 'https://c5-microsite.vercel.app/',
                smallImg: require('../../../assets/images/portfolio13_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio18.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio18.jpg'),
                animate: 'fadeInLeft',
                link: 'https://super-alpha-five.vercel.app/',
                smallImg: require('../../../assets/images/portfolio18_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio16.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio16.jpg'),
                animate: 'fadeInUp',
                link: 'https://xenodochial-hamilton-2b1d36.netlify.app/',
                smallImg: require('../../../assets/images/portfolio16_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio17.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio17.jpg'),
                animate: 'fadeInRight',
                link: 'https://first-coast.vercel.app/',
                smallImg: require('../../../assets/images/portfolio17_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio3.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio3.jpg'),
                animate: 'fadeInLeft',
                link: 'https://dark-illusion.vercel.app/',
                smallImg: require('../../../assets/images/portfolio3_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio15.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio15.jpg'),
                animate: 'fadeInDown',
                link: 'https://game-up-africa.vercel.app/',
                smallImg: require('../../../assets/images/portfolio15_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio4.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio4.jpg'),
                animate: 'fadeInRight',
                link: 'https://eye-care-nine.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio4_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio14.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio14.jpg'),
                animate: 'fadeInLeft',
                link: 'https://magic-mashrooms.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio14_small.jpg'),
            },

            {
                imgSrc: require('../../../assets/images/portfolio6.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio6.jpg'),
                animate: 'fadeInDown',
                link: 'https://top-max.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio6_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio5.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio5.jpg'),
                animate: 'fadeInRight',
                link: 'https://cheat-layers.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio5_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio8.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio8.jpg'),
                animate: 'fadeInLeft',
                link: 'https://garniche.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio8_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio7.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio7.jpg'),
                animate: 'fadeInUp',
                link: 'https://decoria.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio7_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio2.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio2.jpg'),
                animate: 'fadeInRight',
                link: 'https://creative-rabel.vercel.app/',
                smallImg: require('../../../assets/images/portfolio2_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio10.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio10.jpg'),
                animate: 'fadeInLeft',
                link: 'https://logi-truck.vercel.app/',
                smallImg: require('../../../assets/images/portfolio10_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio9.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio9.jpg'),
                animate: 'fadeInDown',
                link: 'https://socialized-globe.vercel.app/',
                isWhite: true,
                smallImg: require('../../../assets/images/portfolio9_small.jpg'),
            },
            {
                imgSrc: require('../../../assets/images/portfolio1.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio1.jpg'),
                animate: 'fadeInRight',
                link: 'https://collection-name.vercel.app/',
                smallImg: require('../../../assets/images/portfolio1_small.jpg'),
            },
        ];
    }

    const btnWithPort = "bg-[#006363] w-fit py-6 px-8 mx-auto fs22 text-[#fff] gap-4 font-semibold max-[1500px]:gap-3 max-[1500px]:py-5 max-[1500px]:px-6 max-[1370px]:px-4 max-[1370px]:py-4 max-[1370px]:gap-2";
    const btnWithoutPort = "bg-[#006363] w-fit py-6 px-8 mx-auto fs22 text-[#fff] flex-row-reverse gap-4 font-semibold max-[1500px]:gap-3 max-[1500px]:py-5 max-[1500px]:px-6 max-[1370px]:px-4 max-[1370px]:py-4 max-[1370px]:gap-2"

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleZoomIconClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    return (
        <>
            {
                lightboxOpen &&
                <LightBox
                    images={portfolioArr}
                    selectedImage={selectedImage}
                    closeLightbox={closeLightbox}
                    lightLink={portfolioArr.link}
                />
            }
            <section className='py100 relative z-10'>
                <div className="container">
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                        <span className='block text-center fs20 text-[#1D2C38] font-bold mb-3'>Our Recent Work</span>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                        <h2 className='text-center text-[#1D2C38] fs45 font-bold'>Checkout <span className='text-[#04B0B0]'> Our Portfolio </span></h2>
                    </ScrollAnimation>
                    <div className='flex justify-between flex-wrap gap-y-14 py80 min-[1690px]:mb-0 mb-10 max-[1500px]:gap-y-12 max-[1370px]:gap-y-9'>
                        {
                            portfolioArr.map((portfolioData, index) => (
                                <ScrollAnimation key={index} className={`portfolioBox ${portfolioData.isWhite ? 'border-[1px] border-[#a6a7ab] rounded-[20px]' : ''}`} animateIn={`${portfolioData.animate}`} animateOnce={true}>
                                    <div className='h-full bg-cover bg-center bg-no-repeat' style={{backgroundImage: `url(${portfolioData.smallImg})`}}>
                                        <img src={portfolioData.imgSrc} alt="Portfolio project at Pixel Pace Technologies" loading='lazy' className='portfolioImg'/>
                                    </div>
                                    <div className='h-full bg-cover bg-center bg-no-repeat absolute' style={{backgroundImage: `url(${portfolioData.smallImg})`}}>
                                        <img src={portfolioData.imgSrcShadow} alt="Portfolio project at Pixel Pace Technologies" loading='lazy' className='portfolioShadow'  />
                                    </div>
                                    <img src={zoomIcon} alt="Zoom In" className='zoomIcon' onClick={() => handleZoomIconClick(portfolioData.imgSrc)} />
                                    <a className='absolute w-full h-full left-0 right-0 top-0 bottom-0 z-30' href={portfolioData.link} target='_blank'></a>
                                    <div className='flex gap-1 items-center previewIcon' rel="noopener noreferrer">
                                        <p className='text-white fs28 font-medium'>Preview</p>
                                        <img src={previewIcon} className='max-[1370px]:w-[20px]' alt="Preview Icon" />
                                    </div>
                                </ScrollAnimation>
                            ))
                        }
                    </div>
                    <ScrollAnimation animateIn='fadeInDown' animateOnce={true} >
                        {
                            isSpecificPage ?
                                <Button shadowClr='rgba(0,0,0,0.15)' btnTxt="Book a Discovery Call" btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnIcon={<PhoneIcon iconClr='#fff' className='w-[28px] max-[1680px]:w-[24px] max-[1500px]:w-[20px] max-[1370px]:w-[15px]' />} className={`${btnWithPort} before:bg-black`} />
                                : <Link to='/Portfolio'>
                                    <Button shadowClr='rgba(0,0,0,0.75)' btnTxt="View More Portfolio" btnIcon={<PortfolioArrow iconClr='#fff' className='w-[28px] max-[1680px]:w-[24px] max-[1500px]:w-[20px] max-[1370px]:w-[15px]' />} className={`${btnWithoutPort} before:bg-black`} />
                                </Link>
                        }
                    </ScrollAnimation>


                </div>
            </section>
        </>
    )
}

export default PortfolioWrap
