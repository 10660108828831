// BlogPage.js
import React, { useState } from 'react';
import BlogCard from './BlogCard';
import Filter from './Filter';
import Pagination from './Pagination';
import BlogPostJson from './blogPosts.json';
import BrowserTabTitle from '../../components/browserTabTitle'

// Function to dynamically import images from a specified directory
const importAllImages = (context) => {
    let images = {};
    context.keys().forEach((item) => {
        images[item.replace('./', '')] = context(item);
    });
    return images;
};

// Dynamically import all images from the assets/images directory
const images = importAllImages(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/));

const BlogPage = () => {
    const [currentCategory, setCategory] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 6;

    // Calculate total pages based on filtered posts
    const filteredPosts = currentCategory === "All" ? BlogPostJson : BlogPostJson.filter(post => post.category === currentCategory);
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

    // Get posts for current page
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
        <BrowserTabTitle tabTitle={'Pixel Pace Blogs | Web Development & Digital Marketing Solutions'} metaDescription={"Stay updated with the latest in web development, SEO, and digital marketing. Read expert insights on the Pixel Pace Technologies blogs."}/>
            <div className="text-center py80 bg-[#006363]">
                <div className="container">
                    <h1 className="fs50 text-white mb-4">Our Blogs</h1>
                    <p className="text-lg text-white">Insights, tips, and stories from our team.</p>
                </div>
            </div>
            <section className="py100">
                <div className="container">
                    <h2 className='text-center fs45 lg:mb-8 mb-6'>
                        All Categories
                    </h2>
                    {/* Filter Component */}
                    <Filter currentCategory={currentCategory} setCategory={setCategory} />
                    <h2 className='text-center fs45 mb-8'>
                        Latest Blogs
                    </h2>
                    {/* Blog Posts Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
                        {currentPosts.map((post, index) => (
                           <BlogCard key={index} post={post} images={images} />
                        ))}
                    </div>

                    {/* Pagination Component */}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </section>

        </>

    );
};

export default BlogPage;
